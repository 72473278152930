<template>
  <el-form-item label="所属范围" class="light-label" size="small" style="line-height:none">
    <el-cascader
      v-model="selectId"
      :options="[selectProjectWithRoom]"
      :props="props"
      clearable
      :show-all-levels="false"
    ></el-cascader>
  </el-form-item>
</template>
<script>
import clone from '@/utils/clone';

export default {
  name: 'project-level-selector',
  data() {
    return {
      selectId: '',
      shortNodeList: [],
      selectItem: null,
      props: {
        emitPath: false,
        checkStrictly: true,
        value: 'IdStr',
        label: 'ModelName',
        children: 'Children',
      },
    };
  },
  mounted() {
    this.selectId = this.selectProject.IdStr;
    this.selectItem = this.selectProject;
  },
  computed: {
    selectProject() {
      return this.$store.getters.selectProject;
    },
    selectProjectWithRoom() {
      const selectProjectWithRoom = clone(this.selectProject);
      this.getRoomWithoutChild(selectProjectWithRoom);
      return selectProjectWithRoom;
    },
  },
  watch: {
    selectId() {
      this.selectItem = this.getSelectItemById(this.selectProject);
    },
    selectItem() {
      this.$emit('selectItem', this.selectItem);
    },
  },
  methods: {
    getRoomWithoutChild(info) {
      if (info.oType === 'pds') {
        // eslint-disable-next-line no-param-reassign
        info.Children = null;
      } else if (info.Children) {
        for (let i = 0; i < info.Children.length; i += 1) {
          const child = info.Children[i];
          this.getRoomWithoutChild(child);
        }
      }
    },
    getSelectItemById(item) {
      if (item.IdStr === this.selectId) {
        return item;
      }
      if (!item.Children) {
        return null;
      }
      for (let i = 0; i < item.Children.length; i += 1) {
        const info = this.getSelectItemById(item.Children[i]);
        if (info) {
          return info;
        }
      }
      return null;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-form-item--small .el-form-item__label{
    font-size: 16px;
    line-height:1
}
</style>
