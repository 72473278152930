import { request } from '@/utils/request/request';

export function getOrderList(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/JPTQ_OrderList',
    method: 'GET',
    params,
  });
}
// 获取筛选数据
export function getOrderSelectorData(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/Get_WarningTypeTree',
    method: 'GET',
    params,
  });
}
// 获取工单详情
export function getOrderDetail(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/GongDan_GetInfo',
    method: 'GET',
    params,
  });
}
// 分派工单
export function getAssignOrder(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/GongDan_FenPai',
    method: 'GET',
    params,
  });
}
// 审核工单
export function getCheckOrder(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/GongDan_ShenHe',
    method: 'GET',
    params,
  });
}
// 撤销工单
export function getWithdrawOrder(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/GongDan_CheHui',
    method: 'GET',
    params,
  });
}
// 获取新告警
export function getNewAlarm(params = {}) {
  return request({
    url: 'api/APPAPI_NEW',
    method: 'GET',
    params,
  });
}
