<template>
  <div class="project-initiative-repair flex-grow-1 d-flex flex-column">
    <Backup class="align-self-start"></Backup>
    <RepairSelector
      class="selector-container section-container dark"
      style="margin-bottom: 1rem"
      @selectItem="selectItemListener"
    ></RepairSelector>
    <el-row class="none-height flex-grow-1" type="flex" :gutter="12">
      <el-col :span="4" class="d-flex flex-column">
        <SectionFrame
          class="flex-grow-1 justify-content-around"
          style="padding-left: 20%"
        >
          <ConditionItem v-for="(count,index) in orderCount" :key="index"
           :countInfo="count"></ConditionItem>
        </SectionFrame>
      </el-col>
      <el-col :span="20" class="d-flex flex-column dark" style="background: #181D62">
        <el-table border stripe class="flex-grow-1 dark" size="small"
        @row-click="orderClickListener"
        :data="orderList"
        height="0"
        style="background: #181D62"
        :row-class-name="(...nu)=>{return 'dark text-white'}">
          <el-table-column label="工单编号" prop="BianHao"></el-table-column>
          <el-table-column
            label="告警名称"
            prop="YuJingMingCheng"
          ></el-table-column>
          <el-table-column
            label="所属项目"
            prop="SuoShuXiangMu"
          ></el-table-column>
          <el-table-column
            label="故障地点"
            prop="GuZhangDiDian"
          ></el-table-column>
          <el-table-column
            label="事件类型"
            prop="ShiJianLeiXing"
          ></el-table-column>
          <el-table-column
            label="告警时间"
            prop="YuJingShiJian"
          ></el-table-column>
          <el-table-column label="工单阶段" prop="NodeName"></el-table-column>
          <el-table-column
            label="工单状态"
            prop="GongDanZhuangTai"
          ></el-table-column>
          <el-table-column label="工单类型" prop="OrderType"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 页数 -->
    <div
      v-if="pageTotal"
      style="
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1rem;
      "
      class="section-container dark"
    >
      <el-pagination
        style="margin-left: auto"
        background
        layout="total, prev, pager, next"
        class="text-white"
        :total="pageTotal"
        :current-page.sync="pageIndex"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import ProjectMixin from '@/mixins/project-mixin';
import RepairSelector from '@/components/project-initiative-repair/repair-selector.vue';
import ConditionItem from '@/components/project-initiative-repair/condition-item.vue';
import GeneralNoPng from '@/assets/images/project/initiative-repair/general-no.png';
import FileNoPng from '@/assets/images/project/initiative-repair/file-no.png';
import TimelinessRatioPng from '@/assets/images/project/initiative-repair/timeliness-ratio.png';
import EmsNoPng from '@/assets/images/project/initiative-repair/ems-no.png';
import UserSmsNoPng from '@/assets/images/project/initiative-repair/user-sms-no.png';
import EmergencySmsNoPng from '@/assets/images/project/initiative-repair/emergency-sms-no.png';
import { getOrderList } from '@/api/project/initiative-repair';
import { getSelectCourtIds } from '@/utils/utils';

export default {
  name: 'project-initiative-repair',
  mixins: [ProjectMixin],
  components: {
    RepairSelector,
    ConditionItem,
  },
  data() {
    return {
      iconList: [{
        img: GeneralNoPng,
        title: '生成数量',
        prop: 'GongDan',
        unit: '件',
      }, {
        img: FileNoPng,
        title: '归档数量',
        prop: 'GuiDang',
        unit: '件',
      }, {
        img: TimelinessRatioPng,
        title: '及时率',
        prop: 'JiShiLv',
        unit: '%',
      }, {
        img: EmsNoPng,
        title: '发送短信总数',
        prop: 'FaSongDuanXinShuLiang',
        unit: '条',
      }, {
        img: UserSmsNoPng,
        title: '用户短信数',
        prop: 'YongHuDuanXinShu',
        unit: '条',
      }, {
        img: EmergencySmsNoPng,
        title: '抢修人员短信数',
        prop: 'QiangXiuRenYuanDuanXinShu',
        unit: '条',
      }],
      orderList: [],
      orderCount: [],
      pageTotal: 0,
      pageIndex: 1,
      pageSize: 10,
      selectQuery: null,
      titleList: [
        {
          title: '实时数据',
          value: 0,
        },
        {
          title: '线损查询',
          value: 1,
        },
      ],
    };
  },
  computed: {
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  watch: {
    pageIndex() {
      this.requestOrderList();
    },
  },
  methods: {
    orderClickListener(row) {
      console.log('row', row);
      this.$router.push({
        name: 'project-order-detail',
        params: {
          eId: row.EWIDstr,
          oId: row.OrderIDstr ? row.OrderIDstr : 'xxx',
        },
      });
    },
    selectItemListener(selectItem) {
      this.selectQuery = selectItem;
      console.log('this.selectQuery', this.selectQuery);
      this.requestOrderList();
    },
    requestOrderList() {
      const [start, end] = this.selectQuery.selectPeriod;
      const params = {
        Stage: this.selectQuery.stage,
        PageSize: this.pageSize,
        PageIndex: this.pageIndex,
        TaskIDs: getSelectCourtIds(this.selectProject, 'pds').toString(),
        dt_Begin: start,
        dt_End: end,
        WarningTypeS: this.selectQuery.warnType.toString(),
      };
      getOrderList(params).then((res) => {
        console.log('getOrderList', res);
        this.orderList = res.list.List || res;
        this.pageTotal = res.total;
        this.orderCount = this.getOrderCount(res.list.Count);
      });
    },
    getOrderCount(countInfo) {
      return this.iconList.map((info) => ({
        ...info,
        value: countInfo[info.prop],
      }));
    },
  },
};
</script>
<style lang='less' scoped>
// /deep/.el-table.dark th, .el-table.dark thead.is-group th {
//   color:#8696BF;
//   border:none;
//   background-color: transparent;
// }
// /deep/.el-table--border.dark td, .el-table--border.dark th.is-leaf, .el-table--border.dark,
// .el-table--group.dark{
//   border:none;
// }
// /deep/.el-table--border td, .el-table--border th,
//  .el-table__body-wrapper .el-table--border
//  .is-scrolling-left~.el-table__fixed{
// border:none;
// }
// /deep/.el-table--striped .el-table__body tr.el-table__row--striped.dark{
// background-color: #181D62;
// }
// /deep/.el-table tr{
//   background-color: #181D62!important;
// }

.el-table.dark th{
    color: #8696BF;
}
.el-table--border td, .el-table--border th, .el-table__body-wrapper
.el-table--border.is-scrolling-left~.el-table__fixed{
  border:none;
}
/deep/.el-table.dark th, .el-table.dark thead.is-group th {
  color:#8696BF;
  border:none;
  background-color: transparent;
}
// /deep/.el-table--border, .el-table--group{
//   border:none;
// }
/deep/.el-table--border.dark td, .el-table--border.dark th.is-leaf,
 .el-table--border.dark, .el-table--group.dark{
border:none;
}
/deep/.el-table--border td, .el-table--border th, .el-table__body-wrapper
 .el-table--border.is-scrolling-left~.el-table__fixed{
border:none;
background-color: #151A59;
}
/deep/.el-table--striped .el-table__body tr.el-table__row--striped.dark td{
//  background: linear-gradient(15deg, #1C4596, #181D62);
 background-color: transparent;
}
/deep/.el-table.dark tr, .el-table.dark, .el-table tr.dark{
  // background: linear-gradient(75deg, #1C4596, #181D62);
  // background: transparent;
}
/deep/.el-table--border.dark::after, .el-table--group.dark::after, .el-table.dark::before{
background: transparent;
}
/deep/.el-button--primary{
  color: #25C1FF;
  border:1px solid hsl(197, 100%, 57%);
  // background: linear-gradient(15deg, #1C4596, #181D62)
  background: #181D62;
}
</style>
