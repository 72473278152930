<template>
  <div class="condition-item d-flex align-items-center">
    <img :src="countInfo.img"  class="condition-image"/>
    <div class="info-container d-flex flex-column align-items-start">
      <div class="item-title">{{countInfo.title}}</div>
      <div class="item-content">
        <span class="item-value">{{countInfo.value}}</span>
        <span class="item-unit">{{countInfo.unit}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import GeneralNoPng from '@/assets/images/project/initiative-repair/general-no.png';

export default {
  name: 'condition-item',
  props: {
    countInfo: {
      type: Object,
      default() {
        return {
          img: GeneralNoPng,
          title: '测试',
          value: 100,
          unit: 'v',
        };
      },
    },
  },
  data() {
    return {

    };
  },
};
</script>
<style scoped>
.item-content{
  color:#25C1FF;
}
.info-container{
  margin-left: 1rem;
}
.item-value{
  font-size: 2rem;
  /* padding-right:5%; */
}
.item-unit{
  font-size: 0.8rem;
  color: #7384BD;
}
.condition-image{
  width: 3rem;
  height: 3rem;
}
</style>
