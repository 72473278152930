<template>
  <el-form :inline="true" size="small" class="dark repair-selector">
    <template v-if="selectorInfo">
    <el-form-item label="故障类型:" class="light-label">
      <el-cascader
        v-model="form.warnType"
        :options="selectorInfo.WarningTypeTree"
        :props="warnProps"
        :show-all-levels="false"
        collapse-tags
        clearable
      ></el-cascader>
      </el-form-item>
        <el-form-item label="工单状态:" class="light-label">
      <el-select v-model="form.stage">
        <el-option v-for="(stage,index) of stageList" :key="index"
         :label="stage.Value" :value="stage.Key"></el-option>
      </el-select>
    </el-form-item>
    </template>
    <ProjectLevelSelector
      @selectItem="selectProjectListener"
    ></ProjectLevelSelector>
    <el-form-item label="故障时间:" class="light-label">
      <el-date-picker
        v-model="form.selectPeriod"
        type="daterange"
        :picker-options="pickerOptions"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="mini" @click="onSubmit"
       style="background-color: #183D7C;border:1px solid #25C1FF">查询</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import ProjectLevelSelector from '@/components/utils/project-level-selector.vue';
import { formatDate } from '@/utils/utils';
import { getOrderSelectorData } from '@/api/project/initiative-repair';

export default {
  name: 'repair-selector',
  components: {
    ProjectLevelSelector,
  },
  data() {
    return {
      warnProps: {
        multiple: true,
        label: 'Name',
        value: 'TabIDstr',
        children: 'C',
        emitPath: false,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      form: {
        selectPeriod: [
          // formatDate(new Date(), 'yyyy-MM-01'),
          formatDate(new Date(), 'yyyy-MM-dd'),
          formatDate(new Date(), 'yyyy-MM-dd'),
        ],
        stage: '',
        warnType: '',
      },
      selectPro: null,
      // 选项数据
      selectorInfo: null,
    };
  },
  created() {
    this.requestOrderSelectorData();
  },
  mounted() {
    this.onSubmit();
  },
  computed: {
    accountId() {
      return this.$store.getters.accountId;
    },
    stageList() {
      if (!this.selectorInfo) {
        return [{
          Key: '',
          Value: '全部',
        }];
      }
      return [{
        Key: '',
        Value: '全部',
      }, ...this.selectorInfo.GongDanJieDuan];
    },
  },
  methods: {
    requestOrderSelectorData() {
      getOrderSelectorData({
        UIDstr: this.accountId,
      }).then((res) => {
        this.selectorInfo = res;
      });
    },
    selectProjectListener(selectItem) {
      this.selectPro = selectItem;
    },
    onSubmit() {
      console.log('this.selectPro', this.selectPro);
      this.$emit('selectItem',
        { ...this.selectPro, ...this.form });
    },
  },
};
</script>
